import alite from 'alite';

document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector('.js-form-affiliate');
  const url = '/.netlify/functions/form-affiliate';

  if (form === null) return;

  form.addEventListener('submit', e => {
    const nameEl = form.querySelector('[name="name"]');
    const phoneEl = form.querySelector('[name="phone"]');
    const companyEl = form.querySelector('[name="company"]');
    const emailEl = form.querySelector('[name="email"]');
    const agreementEl = form.querySelector('[name="agreement"]');
    const successEl = form.querySelector('.js-form-success');

    e.preventDefault();

    const data = {
      name: nameEl.value,
      company: companyEl.value,
      email: emailEl.value,
      phone: phoneEl.value,
    };

    if (!data.company) {
      companyEl.classList.add('-invalid');
      companyEl.focus();

      return;
    }

    if (!data.email) {
      emailEl.classList.add('-invalid');
      emailEl.focus();

      return;
    }

    if (!data.phone) {
      phoneEl.classList.add('-invalid');
      phoneEl.focus();

      return;
    }

    if (!agreementEl.checked) {
      agreementEl.classList.add('-invalid');

      return;
    }

    alite({ url, data, method: 'POST' });
    successEl.classList.add('active');
    agreementEl.classList.remove('-invalid');
    form.reset();
    setTimeout(() => {
      successEl.classList.remove('active');
    }, 10000);
  });
});
