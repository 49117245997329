document.addEventListener('DOMContentLoaded', () => {
  const popupTriggers = document.querySelectorAll('[data-popup-trigger]');

  if (popupTriggers.length === 0) return;

  popupTriggers.forEach(elem => {
    elem.addEventListener('click', () => {
      const { popupTrigger } = elem.dataset;
      const popup = document.querySelector(`[data-popup="${popupTrigger}"]`);
      const closeBtn = popup.querySelector('.js-popup__close');
      const popupBlock = popup.querySelector('.js-popup-block');
      const { body } = document;

      popup.classList.add('popup-overlay_active');
      popupBlock.classList.add('popup_active');
      body.style.overflow = 'hidden';

      closeBtn.addEventListener('click', () => {
        popup.classList.remove('popup-overlay_active');
        popupBlock.classList.remove('popup_active');
        body.style.overflow = 'visible';
      });

      popup.addEventListener('click', (e) => {
        if(!popupBlock.contains(e.target)) {
          popup.classList.remove('popup-overlay_active');
          popupBlock.classList.remove('popup_active');
          body.style.overflow = 'visible';
        }
      });
    });
  });
});
