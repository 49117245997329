import alite from 'alite';

document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector('.js-subscribe-form');
  const emailEl = document.querySelector('.js-subscribe-form__email');
  const successEl = document.querySelector('.js-subscribe-form__success');

  const url = '/.netlify/functions/mailchimp';

  function on(el, event, cb) {
    if (el.attachEvent) {
      el.attachEvent(event, cb);
    } else {
      el.addEventListener(event, cb);
    }
  }

  on(form, 'submit', e => {
    e.preventDefault();
    const data = {
      email: emailEl.value,
    };

    if (!data.email) {
      emailEl.classList.add('-invalid');
      emailEl.focus();

      return;
    }

    emailEl.classList.add('-success');

    alite({ url, data, method: 'POST' });
    successEl.classList.add('subscribe-form__success_active');
    form.reset();

    setTimeout(() => {
      successEl.classList.remove('subscribe-form__success_active');
    }, 4000);
  });

  on(emailEl, 'change', () => {
    emailEl.classList.remove('-invalid');
  });
});
