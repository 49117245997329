document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.js-spyscroll-section');
  const headerHeight = document.querySelector('.js-header').offsetHeight;
  const magicOffset = headerHeight + 5;
  if (sections.length === 0) return;

  function initSpyScroll() {
    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

    sections.forEach(elem => {
      if (elem.offsetTop <= scrollPos + magicOffset) {
        const { id } = elem;
        document.querySelector('.header-submenu__item--active').classList.remove('header-submenu__item--active');
        document.querySelector(`a[href*=${id}]`).classList.add('header-submenu__item--active');
      }
    });
  }

  window.addEventListener('scroll', initSpyScroll);
});
