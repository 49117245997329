document.addEventListener('DOMContentLoaded', () => {
  const dropdown = document.querySelector('.js-dropdown');
  if (dropdown === null) return;

  const dropdownWrapper = dropdown.querySelector('.dropdown__wrapper');
  const dropdownItems = dropdown.querySelectorAll('.dropdown__item');
  const dropdownCurrent = dropdown.querySelector('.dropdown__current');
  const dropdownInput = dropdown.querySelector('.js-dropdown-input');

  function toggleDropdown(e) {
    if (dropdown.contains(e.target)) {
      dropdownWrapper.classList.toggle('dropdown__wrapper_active');
    } else {
      dropdownWrapper.classList.remove('dropdown__wrapper_active');
    }
  }

  function selectDropdownItem() {
    const event = document.createEvent('HTMLEvents');

    dropdownCurrent.textContent = this.textContent;
    dropdownInput.value = this.dataset.programType;
    event.initEvent('change', false, true);
    dropdownInput.dispatchEvent(event);
  }

  window.addEventListener('click', toggleDropdown);
  dropdownItems.forEach(elem => {
    elem.addEventListener('click', selectDropdownItem);
  });
});
