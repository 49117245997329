import alite from 'alite';

document.addEventListener('DOMContentLoaded', () => {
  const forms = document.querySelectorAll('.js-form-demo-card');
  const toggleDemoFormBtns = document.querySelectorAll('.js-toggle-demo-form');
  const url = '/.netlify/functions/form-demo-card';

  if (toggleDemoFormBtns.length === 0) return;

  function toggleDemoForm() {
    const screens = document.querySelectorAll('.js-demo-card__item');
    screens.forEach(elem => {
      elem.classList.toggle('demo-card__item_active');
    });
  }

  toggleDemoFormBtns.forEach(elem => {
    elem.addEventListener('click', toggleDemoForm);
  });

  forms.forEach(elem => {
    elem.addEventListener('submit', e => {
      const nameEl = elem.querySelector('[name="name"]');
      const phoneEl = elem.querySelector('[name="phone"]');
      const successEl = elem.querySelector('.js-form-success');

      function formatPhoneNumber(str) {
        let result = '';
        const removedNonDigits = str.replace(/\D/g, '');
        if (removedNonDigits[0] === '8') {
          const splittedNumber = removedNonDigits.split('');
          splittedNumber[0] = '7';
          result = splittedNumber.join('');
        } else {
          result = removedNonDigits;
        }

        return result;
      }

      e.preventDefault();

      const data = {
        name: nameEl.value,
        phone: formatPhoneNumber(phoneEl.value),
      };

      if (!data.name) {
        nameEl.classList.add('-invalid');
        nameEl.focus();

        return;
      }

      if (!data.phone) {
        phoneEl.classList.add('-invalid');
        phoneEl.focus();

        return;
      }

      alite({ url, data, method: 'POST' });
      successEl.classList.add('active');
      elem.reset();
      setTimeout(() => {
        successEl.classList.remove('active');
      }, 4000);
    });
  });
});
