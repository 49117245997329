document.addEventListener('DOMContentLoaded', () => {
  const dropdownInput = document.querySelector('.js-dropdown-input');
  const sliderInputs = document.querySelectorAll('.js-slider-input');

  if (dropdownInput === null && sliderInputs.length === 0) return;

  const totalElem = document.querySelector('.js-calc-total');
  const { calcCountry } = document.querySelector('[data-calc-country]').dataset;
  let programType = 1;
  const tariff = {
    ru: {
      basic: 5000,
      medium: 10000,
      max: 15000,
    },
    us: {
      basic: 100,
      medium: 500,
      max: 1500,
    }
  };

  function printTotal(val) {
    totalElem.textContent = `= ${val.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}`;
  }

  function calcTotal(type) {
    const basicInputVal = Number(document.querySelector('#slider-basic-input').value);
    const mediumInputVal = Number(document.querySelector('#slider-medium-input').value);
    const maxInputVal = Number(document.querySelector('#slider-max-input').value);
    const basicSum = tariff[calcCountry].basic * 12 * basicInputVal;
    const mediumSum = tariff[calcCountry].medium * 12 * mediumInputVal;
    const maxSum = tariff[calcCountry].max * 12 * maxInputVal;
    const subtotal = basicSum + mediumSum + maxSum;
    let totalSum = 0;

    if (type === 1) {
      totalSum = subtotal * 0.15;
    } else if (type === 2) {
      if (subtotal < 500000) {
        totalSum = subtotal * 0.25;
      } else if (subtotal >= 500000 && subtotal < 800000) {
        totalSum = subtotal * 0.3;
      } else if (subtotal >= 800000 && subtotal < 1200000) {
        totalSum = subtotal * 0.4;
      } else if (subtotal >= 1200000) {
        totalSum = subtotal * 0.5;
      }
    }
    printTotal(totalSum);
  }
  calcTotal(programType);

  dropdownInput.addEventListener('change', () => {
    programType = Number(dropdownInput.value);
    calcTotal(programType);
  });
  sliderInputs.forEach(elem => {
    elem.addEventListener('change', () => calcTotal(programType));
  });
});
