import noUiSlider from 'nouislider';

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('.js-slider');
  const slidersStartVal = [25, 5, 1];

  if (sliders.length === 0) return;

  sliders.forEach((slider, index) => {
    const sliderElem = slider.querySelector('.js-slider-elem');
    const sliderValue = slider.querySelector('.js-slider-val');
    const sliderInput = slider.querySelector('.js-slider-input');

    noUiSlider.create(sliderElem, {
      range: {
        min: 0,
        max: 50,
      },
      connect: [true, false],
      step: 1,
      start: [slidersStartVal[index]],
    });
    sliderElem.noUiSlider.on('slide', function() {
      const event = document.createEvent('HTMLEvents');

      sliderValue.textContent = Number(this.get()).toFixed();
      sliderInput.value = Number(this.get()).toFixed();
      event.initEvent('change', false, true);
      sliderInput.dispatchEvent(event);
    });
  });
});
