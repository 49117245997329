import { tns } from 'tiny-slider/src/tiny-slider';
import './components/smoothscroll';
import './components/spyscroll';
import './components/dropdown';
import './components/calc-slider';
import './components/calc';
import './components/graph';
import './components/form-mailchimp';
import './components/form-contact';
import './components/form-demo-card';
import './components/close-window-btn';
import './components/popup';
import './components/form-affiliate';
import './components/menu-mobile';

// Animate input placeholder
(function() {
  const inputs = document.querySelectorAll('.js-input');
  const header = document.querySelector('.js-header');
  const textarea = document.querySelector('.js-textarea');

  function animateInputOnFocus() {
    this.previousElementSibling.classList.add('form__input-placeholder_filled');
  }

  function animateInputOnBlur() {
    if (this.value === '') {
      this.previousElementSibling.classList.remove('form__input-placeholder_filled');
    }
  }

  function animateHeaderOnInput() {
    if (window.screen.width < 768) {
      header.classList.toggle('invisible');
    }
  }

  inputs.forEach(elem => {
    elem.addEventListener('input', animateInputOnFocus);
    elem.addEventListener('blur', animateInputOnBlur);

    elem.addEventListener('blur', animateHeaderOnInput);
    elem.addEventListener('focus', animateHeaderOnInput);
  });
  textarea.addEventListener('blur', animateHeaderOnInput);
  textarea.addEventListener('focus', animateHeaderOnInput);
})();

// carousel Features
(function() {
  const carousel = document.querySelector('.js-carousel_features');
  if (carousel) {
    const carouselFeatures = tns({
      container: '.js-carousel_features',
      items: 1,
      slideBy: 'page',
      autoplay: false,
      nav: false,
      controls: false,
      autoHeight: true,
      loop: false,
    });

    const carouselPagesCurrentElem = document.querySelector('.js-cur-page_features');
    const carouselPagesCountElem = document.querySelector('.js-count-page_features');
    const carouselPrevElem = document.querySelector('.js-carousel-prev_features');
    const carouselNextElem = document.querySelector('.js-carousel-next_features');

    carouselFeatures.events.on('indexChanged', e => {
      carouselPagesCurrentElem.innerHTML = e.displayIndex;
      carouselNextElem.classList.remove('carouselCtrl__next_animated');
    });
    carouselPagesCountElem.innerHTML = carouselFeatures.getInfo().slideCount;

    carouselPrevElem.onclick = () => {
      carouselFeatures.goTo('prev');
    };
    carouselNextElem.onclick = () => {
      carouselFeatures.goTo('next');
    };
  }
})();

// carousel Review
(function() {
  const carousel = document.querySelector('.js-carousel_review');
  if (carousel) {
    const carouselReview = tns({
      container: '.js-carousel_review',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      nav: false,
      controls: false,
      autoHeight: true,
      loop: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 7000,
      autoplayHoverPause: true,
    });

    const carouselPagesCurrentElem = document.querySelector('.js-cur-page_review');
    const carouselPagesCountElem = document.querySelector('.js-count-page_review');
    const carouselPrevElem = document.querySelector('.js-carousel-prev_review');
    const carouselNextElem = document.querySelector('.js-carousel-next_review');
    const carouselNavElem = document.querySelectorAll('.js-carouselNav_review');

    carouselReview.events.on('indexChanged', e => {
      carouselPagesCurrentElem.innerHTML = e.displayIndex;

      carouselNavElem.forEach(elem => {
        elem.classList.remove('carouselNav__item_active');
        if (Number(elem.dataset.navId) === e.index - 1) {
          elem.classList.add('carouselNav__item_active');
        }
      });
    });
    carouselPagesCountElem.innerHTML = carouselReview.getInfo().slideCount;

    carouselPrevElem.onclick = () => {
      carouselReview.goTo('prev');
      carouselReview.pause();
    };
    carouselNextElem.onclick = () => {
      carouselReview.goTo('next');
      carouselReview.pause();
    };

    carouselNavElem.forEach(elem => {
      elem.addEventListener('click', () => {
        const slideId = elem.dataset.navId;
        carouselReview.goTo(slideId);
        carouselReview.pause();
      });
    });
  }
})();
