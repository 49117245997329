document.addEventListener('DOMContentLoaded', () => {
  const menuMobBtn = document.querySelector('.js-menu-mobile-btn');
  const menuMob = document.querySelector('.js-menu-mobile');
  const headerHeight = document.querySelector('.js-header').offsetHeight;
  const menuMobStatus = document.querySelector('.js-menu-mobile-status');
  const { body } = document;

  if (menuMobBtn === null) return;

  menuMob.style.bottom = `-${headerHeight}px`;

  function changeMenuStatus() {
    const event = document.createEvent('HTMLEvents');
    if (menuMobStatus.value === '') {
      menuMobStatus.value = 'active';
    } else {
      menuMobStatus.value = '';
    }

    event.initEvent('change', false, true);
    menuMobStatus.dispatchEvent(event);
  }

  function animateMenu() {
    menuMobBtn.classList.toggle('burger--active');
    menuMob.classList.toggle('active');
    body.classList.toggle('scroll-y-locked');
  }

  menuMobBtn.addEventListener('click', changeMenuStatus);
  menuMobStatus.addEventListener('change', animateMenu);
});
