document.addEventListener('DOMContentLoaded', () => {
  const graphEl = document.querySelector('.js-graph');
  const dropdownInput = document.querySelector('.js-dropdown-input');
  let programType = 1;

  if (dropdownInput === null) return;

  function toggleGraph(type) {
    if (type === 2) {
      graphEl.classList.add('calc__graph_active');
    } else {
      graphEl.classList.remove('calc__graph_active');
    }
  }

  dropdownInput.addEventListener('change', () => {
    programType = Number(dropdownInput.value);
    toggleGraph(programType);
  });
});
