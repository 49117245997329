document.addEventListener('DOMContentLoaded', () => {
  const links = document.querySelectorAll('.js-scroll');
  const headerHeight = document.querySelector('.js-header').offsetHeight;
  const menuMobStatus = document.querySelector('.js-menu-mobile-status');
  if (links.length === 0) return;

  function initSmoothScroll(e, respond = null) {
    const getDistanceToTop = el => Math.floor(el.getBoundingClientRect().top);
    e.preventDefault();
    const targetID = respond ? respond.getAttribute('href') : this.getAttribute('href');
    const targetAnchor = document.querySelector(targetID);
    if (!targetAnchor) return;
    const originalTop = getDistanceToTop(targetAnchor);
    window.scrollBy({ top: originalTop - headerHeight, left: 0, behavior: 'smooth' });
  }

  function changeMenuStatus() {
    const event = document.createEvent('HTMLEvents');
    if (menuMobStatus.value === 'active') {
      menuMobStatus.value = '';
      event.initEvent('change', false, true);
      menuMobStatus.dispatchEvent(event);
    }
  }

  links.forEach(elem => {
    elem.addEventListener('click', initSmoothScroll);
    elem.addEventListener('click', changeMenuStatus);
  });
});
