import alite from 'alite';

document.addEventListener('DOMContentLoaded', () => {
  const form = document.getElementById('client-form');
  const container = document.getElementById('form-container');
  const nameEl = document.getElementById('client-name');
  const phoneEl = document.getElementById('client-phone');
  const commentEl = document.getElementById('client-comment');

  const url = '/.netlify/functions/contact';

  function on(el, event, cb) {
    if (el.attachEvent) {
      el.attachEvent(event, cb);
    } else {
      el.addEventListener(event, cb);
    }
  }

  on(form, 'submit', e => {
    e.preventDefault();
    const data = {
      name: nameEl.value,
      phone: phoneEl.value,
      comment: commentEl.value,
    };

    if (!data.phone) {
      phoneEl.classList.add('-invalid');
      phoneEl.focus();

      return;
    }

    container.classList.add('-success');

    alite({ url, data, method: 'POST' });
    form.reset();

    setTimeout(() => {
      container.classList.remove('-success');
    }, 4000);
  });

  on(phoneEl, 'change', () => {
    phoneEl.classList.remove('-invalid');
  });
});
